<template>
  <b-card>
    <empty-accommodations-table
      show-search
      :items="accommodations"
      :loading="loading"
    />
  </b-card>
</template>

<script>
import EmptyAccommodationsTable from "@/views/accommodations/empty-list/components/EmptyAccommodationsTable.vue";
import { BCard } from "bootstrap-vue";
import { notifyError } from "@/utils/methods";
import {
  formatDateStringToDatabaseDate,
  formatDateStringToDate,
} from "@/utils/formatters";

export default {
  components: {
    BCard,
    EmptyAccommodationsTable,
  },
  computed: {
    loading() {
      return this.$store.getters["accommodations/loading"];
    },
    accommodations() {
      return this.$store.getters["accommodations/emptyAccommodations"].map(
        (accommodation) => ({
          uuid: accommodation.uuid || "No definit",
          name: accommodation.name || "No definit",
          location: accommodation.location?.city || "No definit",
          sortNextCheckinDate: formatDateStringToDatabaseDate(
            accommodation.nextCheckin?.checkin
          ),
          nextCheckinDate: formatDateStringToDate(
            accommodation.nextCheckin?.checkin
          ),
          nextCheckinStatsUuid: accommodation.nextCheckin?.stats?.uuid || null,
          cleaningProvided: accommodation.contract?.cleaningProvided || false,
          cleaningCompleted:
            accommodation.nextCheckin?.stats?.cleaningCompleted || false,
        })
      );
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.$store
        .dispatch("accommodations/fetchFilteredEmptyAccommodations", {
          pagination: false,
        })
        .catch(() =>
          notifyError(
            this.$t("errors.fetchAccommodations.title"),
            this.$t("errors.fetchAccommodations.description")
          )
        );
    },
  },
};
</script>
