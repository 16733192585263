<template>
  <div class="empty-accommodations-table">
    <!-- NEW ACCOMMODATION BUTTON AND SEARCH -->
    <b-row v-if="showSearch" class="mb-2 d-flex flex-wrap justify-content-end">
      <b-col
        v-if="showSearch"
        cols="9"
        md="auto"
        class="mb-1 mb-md-0 justify-content-end"
      >
        <b-form-input
          id="filterInput"
          ref="filterInput"
          v-model="filter"
          type="search"
          :placeholder="`${$t('Cerca')}...`"
          :disabled="loading"
        />
      </b-col>
    </b-row>

    <!-- ACCOMMODATIONS TABLE -->
    <b-row>
      <b-col md="12">
        <b-table
          striped
          borderless
          responsive
          class="position-relative"
          :busy="loading"
          :per-page="itemsPerPage"
          :current-page="currentPage"
          :sort-by="sortBy"
          :sort-desc="sortDesc"
          sort-icon-left
          :items="items"
          show-empty
          :empty-text="$t('errors.emptyTable.title')"
          :empty-filtered-text="$t('errors.emptyTable.title')"
          :fields="fields"
          :filter="filter"
          @filtered="onFiltered"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-2" />
              <strong>{{ $t("Carregant") }}...</strong>
            </div>
          </template>

          <!-- Column: name -->
          <template #cell(name)="row">
            <b-accommodation :uuid="row.item.uuid" :name="row.item.name" />
          </template>

          <!-- Column: sortNextCheckinDate -->
          <template #cell(sortNextCheckinDate)="data">
            {{ data.item.nextCheckinDate }}
          </template>

          <!-- Column: cleaning -->
          <template #cell(cleaning)="data">
            <b-cleaning-status-badge
              pill
              :booking-stats-uuid="data.item.nextCheckinStatsUuid"
              :cleaning-completed="data.item.cleaningCompleted"
              :cleaning-provided="data.item.cleaningProvided"
            />
          </template>
        </b-table>
      </b-col>
    </b-row>

    <!-- PAGINATION -->
    <b-row
      v-show="!loading"
      v-if="totalRows > pageOptions[0]"
      class="d-flex justify-content-between align-items-center"
    >
      <b-col
        cols="12"
        md="4"
        lg="3"
        class="mb-1 mb-md-0 d-flex justify-content-center justify-content-md-start"
      >
        <b-form-group
          id="per-page-input"
          :label="`${$t('Per pàgina')}`"
          label-cols="6"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="itemsPerPage"
            size="sm"
            inline
            :options="pageOptions"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
        lg="3"
        class="mb-1 mb-md-0 d-flex justify-content-center justify-content-md-end"
      >
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="itemsPerPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BTable,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BFormInput,
  BSpinner,
  VBTooltip,
} from "bootstrap-vue";
import BAccommodation from "@foravila-core/components/b-accommodation/BAccommodation.vue";
import BCleaningStatusBadge from "@foravila-core/components/b-cleaning-status-badge/BCleaningStatusBadge.vue";

export default {
  components: {
    BTable,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BFormInput,
    BSpinner,
    BAccommodation,
    BCleaningStatusBadge,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
    showSearch: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      sortBy: "name",
      sortDesc: false,
      pageOptions: [5, 10, 50, 100, 500],
      totalRows: 1,
      currentPage: 1,
      itemsPerPage: 50,
      filter: null,
      fields: [
        { key: "name", label: this.$t("Nom"), sortable: true },
        { key: "location", label: this.$t("Ubicació"), sortable: true },
        {
          key: "sortNextCheckinDate",
          label: this.$t("Propera entrada"),
          sortable: true,
        },
        {
          key: "cleaning",
          label: this.$t("Neteja"),
        },
      ],
    };
  },
  computed: {
    accommodationsCount() {
      return this.$store.getters["accommodations/emptyCount"];
    },
  },
  watch: {
    items(items) {
      this.totalRows = items.length;
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getCleaningBadgeVariant(item) {
      if (!item?.cleaningProvided) {
        return "secondary";
      } else {
        return item?.cleaningCompleted ? "success" : "danger";
      }
    },
    getCleaningBadgeText(item) {
      if (!item?.cleaningProvided) {
        return "Propietari";
      } else {
        return item?.cleaningCompleted ? "Completada" : "Pendent";
      }
    },
  },
};
</script>

<style lang="scss">
.empty-accommodations-table {
  #per-page-input__BV_label_ {
    margin-top: 5px;
  }
}
</style>
